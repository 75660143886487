
























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class Button extends Vue {
    @Prop({}) color!: string
    @Prop({}) dark!: boolean
    @Prop({}) label!: string
    @Prop({ default: false }) text!: boolean
    @Prop({ default: false }) outlined!: boolean
    @Prop({}) to?: string | Record<string, unknown>
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: false }) loading!: boolean
    @Prop({ default: false }) icon!: boolean
    @Prop({ default: false }) large!: boolean
    @Prop({ default: false }) fab!: boolean
    @Prop({ default: false }) small!: boolean
    @Prop({ default: false }) whiteText!: boolean
    @Prop() height!: string

    get buildClass(): string {
        let result = ''
        if (this.whiteText) {
            result = result.concat('white--text')
        }
        return result
    }

    onClick(): void {
        if (!this.disabled) {
            this.$emit('on-click')
        }
    }
}
