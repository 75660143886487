














import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { SectorImage } from '@/components/image-loader/index'

@Component({})
export default class ImageLoader extends Vue {
    @Prop({ type: Array, default: () => [] }) images!: SectorImage[]

    loadedCount = 0

    @Watch('loadedCount')
    onLoadedCountChanged(count: number): void {
        if (count === this.images.length) {
            this.$emit('images-loaded')
        }
    }
}
