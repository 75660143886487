import ImageLoader from '@/components/image-loader/image-loader.vue'

export const sectorIds = {
    sector1: 'sector1',
    sector2: 'sector2',
    sector3: 'sector3',
    sector4: 'sector4'
} as const

export type SectorId = keyof typeof sectorIds

export const sectorImages: SectorImage[] = [
    { id: 'sector1', name: '30/45 Grad Wand', src: require('@/assets/30-45-Grad.jpg') },
    { id: 'sector2', name: '45/10/20 Grad Wand', src: require('@/assets/45-10-20-Grad.jpg') },
    { id: 'sector3', name: '10/20/10 Grad Wand', src: require('@/assets/10-20-10-Grad.jpg') },
    { id: 'sector4', name: '20/10 Grad Ecke', src: require('@/assets/20-10-Ecke.jpg') }
]

export type SectorImage = { id: SectorId; src: string; name: string }

export { ImageLoader }
